<template>
  <div class="introduce">
    <div class="section section1">
      <img :src="require('@/assets/introduce/introduce_bg.png')" class="sample" alt="bg">
      <div class="info">
        <h2>效率提升，超乎想象</h2>
        <p class="desc">
          面向个人和企业的云端原型设计与协同工具，赋能你的设计之旅，<br/>
          让你轻松展示想法、快速验证想法、高效落地想法
        </p>
      </div>
    </div>

    <div class="section section2">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="16">
              <img :src="require('@/assets/home/sample2.jpg')" class="sample" alt="img">
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">轻松上手，创作自如</p>
                <h2>团队协作</h2>
                <p class="desc">
                  团队/项目权限管理支持不同协同场景，满足不同企业和团队的需求。<br/>
                  接口数据实时同步云端。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>成熟项目管理</span>
                  </li>
                  <li>
                    <i></i>
                    <span>高效团队管理</span>
                  </li>
                  <li>
                    <i></i>
                    <span>清晰权限管理</span>
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section3 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h2>API 调试</h2>
          <p class="desc">
            环境变量、前置/后置脚本、全局变量等人性化设计，让接口调试更顺手。
          </p>
          <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
          <a-row :gutter="48">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">自动化校验数据结构。</div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">可视化的断言、提取变量操作。</div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">接口用例轻松用。</div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section4">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">对接开发，沟通顺畅</p>
                <h2>Api文档</h2>
                <p class="desc">
                  Postman 有的功能  都有（如环境变量、前置/后置脚本、Cookie/Session 全局共享等），并且比 Postman 更高效好用。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>文档快捷分享，让协同更自如。</span>
                  </li>
                  <li>
                    <i></i>
                    <span>接口文档完全遵循 OpenAPI(Swagger) 规范。</span>
                  </li>
                </ul>
              </div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="16">
              <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section5 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :span="8">
              <div class="img">
                <img :src="require('@/assets/introduce/notice.png')" alt="bg">
              </div>
            </a-col>
            <a-col :span="16">
              <div class="info">
                <h2>api文档更新强提醒</h2>
                <p class="desc">
                  API文档更新，及时多渠道提醒团队人员，避免出现同步错乱导致的bug
                </p>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section6">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="16">
              <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">随时随地，开启创作</p>
                <h2>API 数据 Mock</h2>
                <p class="desc">
                  无论在公司还是远程，只需打开浏览器，随时可以让工作无缝。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>零学习成本使用mock服务，让前后端开发解耦。</span>
                  </li>
                  <li>
                    <i></i>
                    <span>Mock数据更符合接口定义结构，让mock数据贴近真实。</span>
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section7">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <h2>测试校验</h2>
                <p class="desc">
                  醒目的错误提示，让bug不在隐藏。
                </p>
              </div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="16">
              <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section8 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h2>自动生成代码</h2>
          <p class="desc">严格按照接口定义，一键生成各种语言/框架的业务代码和 API 请求代码。</p>
          <p class="desc">高效率把接口应用到实际业务场景，提升接口接入效率。</p>
          <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
          <a-row :gutter="48">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">根据接口/模型定义，自动生成各种语言/框架的业务代码和 API 请求代码。</div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">支持 TypeScript、Java、Go、Swift、ObjectiveC、Kotlin、Dart、C++、C#、Rust 等 130 种语言及框架。</div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">支持自定义代码模板，自动生成符合自己团队的架构规范的代码，满足各种个性化的需求。</div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section9">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="16">
              <img :src="require('@/assets/home/sample2.jpg')" class="sample" alt="img">
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <h2>数据导入/导出</h2>
                <p class="desc">
                  支持主流数据格式的导入导出，让api管理更自如。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>支持导出 OpenAPI (Swagger)、Markdown、Html 等数据格式</span>
                  </li>
                  <li>
                    <i></i>
                    <span>支持导入 OpenAPI (Swagger)、Postman、HAR、RAP2、JMeter、YApi、Eolinker、NEI、RAML、DOClever 、Apizza 、DOCWAY、ShowDoc、apiDoc、I/O Docs、WADL、Google Discovery 等数据格式。</span>
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section10">
      <router-link to="/user/register">
        <a-button type="primary" class="register-btn">
          <span>立即体验</span>
          <a-icon type="right-circle" />
        </a-button>
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: 'introduce',
  props: {
    
  }
}
</script>

<style lang="scss">
  .introduce {
    text-align: center;
    .section {
      padding: 110px 0 150px;
      position: relative;
      h1 {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 43px;
        color: #182634;
      }
      h2 {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 34px;
        color: #182634;
      }
      h3 {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 26px;
        color: #182634;
      }
      .sub-title {
        font-weight: 400;
        font-size: 21px;
        line-height: 28px;
        color: #182634;
      }
      .desc {
        font-size: 12px;
        line-height: 20px;
        color: #7E8791;
      }
      img.sample {
        width: 100%;
        height: auto;
      }
      &.gray-bg {
        background-color: #FAFAFA;
      }
    }

    .section2,
    .section4,
    .section5,
    .section6,
    .section7,
    .section9 {
      .info {
        padding-top: 30px;
        text-align: left;
      }
      .tooltip {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #0071BC;
      }
      ul {
        li {
          padding-left: 18px;
          margin-top: 20px;
          margin-bottom: 15px;
          position: relative;
          i {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #FF783F;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -4px;
          }
        }
      }
    }

    .section1 {
      padding: 0;
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .section2 {
      text-align: left;
      background: url('../../assets/home/bg_img01.png') no-repeat left 40%;
      background-size: 20% auto;
    }

    .section3 {
      img {
        display: block;
        margin: 38px auto 48px;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        margin-bottom: 25px;
        padding: 0 15px 0 20px;
        height: 100px;
        background: #F4F4F4;
        border-left: 5px solid #0071BC;
      }
    }

    .section4 {
      background: url('../../assets/home/bg_img03.png') no-repeat right 40%;
      background-size: 20% auto;
    }

    .section5 {
      padding: 100px 0 130px;
      .img {
        text-align: right;
        img {
          width: 86px;
          height: auto;
        }
      }
      .info {
        margin: 0;
        padding: 0;
      }
    }

    .section6 {
      background: url('../../assets/home/bg_img02.png') no-repeat left 40%;
      background-size: 20% auto;
    }

    .section7 {
      .info {
        padding-top: 80px;
      }
    }

    .section8 {
      img {
        display: block;
        margin: 38px auto 48px;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        margin-bottom: 25px;
        padding: 0 15px 0 20px;
        height: 100px;
        background: #F4F4F4;
        border-left: 5px solid #0071BC;
      }
    }

    .section10 {
      .register-btn {
        width: 162px;
        height: 52px;
        font-size: 15px;
        border-radius: 26px;
        color: #FFFAF9;
      }
    }

  }
</style>
